

.Prizes{
    width: 32rem;
    width: fit-content;
    margin: auto;
    text-align: left;
    margin-top: 1.7rem;
}
.PrizeHeading h1{
    font-size: 5rem;
    margin-bottom: 2rem;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;

}

@media  (max-width: 570px){
    .PrizeHeading h1{
      font-size: 3.5rem;
    }
}

.PrizeIcon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Prizes p{
    color: rgb(145, 143, 143);
}
.Prizes .first{
    color:#A239EA;
}

.Prizes .second{
    color:#39A2DB;
}

.Prizes .third{
    color:#FF616D;
}

.Prizes .fourth{
    color:#3C8DAD;
}

.Prizes .fifth{
    color:#BF1363;
}

.Prizes .sixth{
    color:#FF96AD;
}

.Prizes .seventh{
    color:#E4BAD4;
}
