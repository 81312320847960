.logoS {
  
}
.Logo {
  width: 100%;
} 


.LogoSectionAbout {
  font-family: 'Nunito', sans-serif;
}
.LogoSectionAbout h1 {
  font-weight: 700;
  text-align: left;
}


@media  (max-width: 570px){
  .LogoSectionAbout h1{
    font-size: 3.5rem;
  }
}

.Logo {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 2s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}